<template>
    <styled-interface hide-toolbar>
        <template #interface-heading>
            Onboarding
        </template>

        <cancel-onboarding-dialog
            v-model="cancelOnboardingDialogOpen"
            :onboarding="onboardingToCancel"
            @complete="onCancelOnboardingComplete" />

        <styled-card>
            <template #heading>
                Account Onboarding Status
            </template>
            <template #action-buttons>
                <div
                    v-if="onboardingsUpdatedAt"
                    class="grey--text">
                    <em>
                        Updated <time-ago :value="onboardingsUpdatedAt" />
                    </em>
                </div>
                <action-button
                    icon="refresh"
                    :loading="onboardingsLoading"
                    @click="refreshOnboardings()">
                    refresh
                </action-button>
            </template>

            <div>
                <styled-table
                    :headers="headers"
                    :items="activeOnboardings"
                    :rows-per-page="50"
                    sort-by="updated_at.date"
                    min-width="1320px"
                    has-search
                    has-filters>
                    <template #items="{ item, index }">
                        <tr
                            :class="[
                                index % 2 ? 'row-even' : 'row-odd'
                            ]">
                            <td class="py-2 px-4">
                                {{ item.dealer.name }}
                            </td>
                            <td class="py-2 px-4">
                                {{ item.type | capitalize }}
                            </td>
                            <td class="py-2 px-4">
                                {{ item.label }}
                            </td>
                            <td class="py-2 px-4">
                                <template v-if="item.user">
                                    {{ item.user.name }}
                                </template>
                                <template v-else>
                                    -
                                </template>
                            </td>
                            <td class="py-2 px-4">
                                {{ $moment.utc(item.created_at.date).local().format('MM/DD/YY @ h:mma') }}
                            </td>
                            <td class="py-2 px-4">
                                {{ $moment.utc(item.updated_at.date).local().format('MM/DD/YY @ h:mma') }}
                            </td>
                            <td class="py-2 px-4">
                                Step {{ item.configuration.currentStep + 1 }}
                            </td>
                            <td class="py-2 px-4 text-xs-right">
                                <div class="layout">
                                    <action-button
                                        icon="trash"
                                        class="mr-4"
                                        @click="onCancelOnboarding(item)">
                                        Cancel
                                    </action-button>
                                    <action-button
                                        icon="outbound-link"
                                        @click="onContinueOnboarding(item)">
                                        Continue
                                    </action-button>
                                </div>
                            </td>
                        </tr>
                    </template>
                </styled-table>
            </div>
        </styled-card>
    </styled-interface>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import CancelOnboardingDialog from '../globals/CancelOnboardingDialog';
import ActionButton from '../globals/ActionButton';
import StyledTable from '../globals/StyledTable';
import StyledInterface from '../globals/StyledInterface';
import StyledCard from '../globals/StyledCard';
import TimeAgo from '../globals/TimeAgo';

export default {
    name: 'AccountOnboardingStatus',
    title: 'Account Onboarding Status',
    components: {
        CancelOnboardingDialog,
        ActionButton,
        StyledCard,
        StyledInterface,
        StyledTable,
        TimeAgo
    },
    data() {
        return {
            loading: false,
            headers: [
                {
                    text: 'Account',
                    align: 'left',
                    width: '20%',
                    sortable: true,
                    value: 'dealer.name',
                    filter: 'search'
                },
                {
                    text: 'Type',
                    align: 'left',
                    width: '10%',
                    sortable: true,
                    value: 'type',
                    filter: 'multiple'
                },
                {
                    text: 'Name',
                    align: 'left',
                    width: '20%',
                    sortable: true,
                    value: 'label',
                    filter: 'multiple'
                },
                {
                    text: 'Created by',
                    align: 'left',
                    width: '20%',
                    sortable: false,
                    value: 'user.name',
                },
                {
                    text: 'Started at',
                    align: 'left',
                    width: '20%',
                    sortable: true,
                    value: 'created_at.date'
                },
                {
                    text: 'Last touch',
                    align: 'left',
                    width: '20%',
                    sortable: true,
                    value: 'updated_at.date'
                },
                {
                    text: 'Left off at',
                    align: 'left',
                    width: '10%',
                    sortable: true,
                    value: 'configuration.currentStep'

                },
                {
                    text: '',
                    align: 'right',
                    width: '150px',
                    sortable: false
                },
            ],
            cancelOnboardingDialogOpen: false,
            onboardingToCancel: {}
        };
    },
    computed: {
        ...mapState({
            currentDealerId: (state) => state.dealer.currentDealerId,
            onboardingsLoading: (state) => state.onboarding.onboardingsLoading,
            onboardingsUpdatedAt: (state) => state.onboarding.onboardingsUpdatedAt,
        }),
        ...mapGetters([
            'activeOnboardings'
        ])
    },
    methods: {
        onContinueOnboarding(onboarding) {
            this.$router.push({
                name: 'dealer-onboarding',
                params: {
                    dealer_id: onboarding.dealer_id,
                    onboarding_id: onboarding.id
                }
            });
        },
        onCancelOnboarding(onboarding) {
            this.onboardingToCancel = onboarding;
            this.cancelOnboardingDialogOpen = true;
        },
        async onCancelOnboardingComplete() {
            this.cancelOnboardingDialogOpen = false;

            if (this.onboardingToCancel.dealer_id == this.currentDealerId) {
                await this.$store.dispatch('updateCurrentDealer');
            }

            this.refreshOnboardings();
        },
        refreshOnboardings() {
            this.$store.dispatch('initActiveOnboardings');
        }
    }
};
</script>

<style lang="scss">
</style>
